const toUniqueArr = (arr) => {
    let uniqueArr = [];
    let ids = {};

    for (let i = 0; i < arr.length; i++) {
        let id = arr[i].id;

        if (!ids[id]) {
            ids[id] = true;
            uniqueArr.push(arr[i]);
        }
    }

    return uniqueArr;
};

export default toUniqueArr;
