import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/Common/Layout";
import Spinner from "../../components/Common/Spinner";
import formatDate from "../../helpers/formatDate";
import toUniqueArr from "../../helpers/toUniqueArr";
import useBlogs from "../../hooks/useBlogs";
import Blog from "../../components/Common/Blog";

const Home = () => {
    // Get Blogs
    const [blogs, setBlogs] = useState([]);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(9);
    const { getBlogs, getLastBlog, isLoading, error, hasMore } = useBlogs();

    const handlePage = () => {
        setPage((prevState) => {
            return prevState - limit;
        });
    };

    useEffect(() => {
        getLastBlog().then((lastBlog) => {
            if (lastBlog.id) {
                setPage(lastBlog.id);
            }
        });
    }, []);

    useEffect(() => {
        getBlogs({ page: page, limit: limit }).then((newBlogs) => {
            if (newBlogs.length > 0) {
                setBlogs(toUniqueArr([...blogs, ...newBlogs]));
            }
        });
    }, [page]);

    return (
        <Layout>
            {/* Game */}
            <section className="bg-white">
                <div className="pb-8 px-4 mx-auto max-w-screen-xl lg:pb-16 lg:px-6">
                    <iframe
                        src="https://www.crazygames.com/embed/soccer-dash"
                        className="w-full h-[65vh] rounded-2xl"
                        frameBorder="0"
                        allow="gamepad *;"
                    ></iframe>
                </div>
            </section>

            {/* Blogs */}
            <section className="bg-white">
                <div className="pb-8 px-4 mx-auto max-w-screen-xl lg:pb-16 lg:px-6">
                    <div className="mx-auto max-w-screen-sm text-center lg:mb-16 mb-8">
                        <h2 className="mb-4 text-3xl lg:text-4xl tracking-tight font-extrabold text-gray-900">
                            Our Blogs
                        </h2>

                        <p className="font-light text-gray-500 sm:text-xl">
                            We use an agile approach to test assumptions and
                            connect with the needs of your audience early and
                            often.
                        </p>
                    </div>

                    {isLoading && (
                        <div className="mx-auto w-fit">
                            <Spinner />
                        </div>
                    )}

                    <div className="grid gap-8 lg:grid-cols-3 mb-8">
                        {blogs.map((blog) => {
                            if (blog?.status === "active") {
                                return <Blog key={blog?.id} blog={blog} />;
                            }
                        })}
                    </div>

                    {hasMore && (
                        <div className="mx-auto w-fit">
                            <button
                                type="button"
                                className="flex items-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-3 focus:outline-none"
                                onClick={handlePage}
                            >
                                {isLoading && <Spinner />}
                                <span>Load More</span>
                            </button>
                        </div>
                    )}
                </div>
            </section>
        </Layout>
    );
};

export default Home;
