import React from "react";
import { Link } from "react-router-dom";
import notFoundImage from "../../images/not-found-icon.svg";
import logo from "../../images/logo.png";

const NotFound = () => {
    return (
        <div className="text-center flex flex-col items-center justify-center h-screen">
            <Link to="/">
                <img className="w-48 mx-auto" src={logo} alt="" />
            </Link>

            <img
                className="w-full mx-auto lg:w-[40%]"
                src={notFoundImage}
                alt=""
            />
            <h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl">
                404 <span className="text-primary-700">Not</span> Found!
            </h1>
        </div>
    );
};

export default NotFound;
