import { Spinner } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useCategories from "../../hooks/useCategories";
import logoWhite from "../../images/logo-white.png";

const Footer = () => {
    // Get Categories
    const [categories, setCategories] = useState([]);
    const { getAllCategories, isLoading } = useCategories();

    useEffect(() => {
        getAllCategories().then((result) => {
            setCategories(result);
        });
    }, []);

    return (
        <section className="bg-gray-900">
            <div className="py-8 px-4 md:px-8 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                    <div>
                        <div className="pb-4">
                            <Link to="/">
                                <img
                                    className="w-48 mx-auto md:mx-0"
                                    src={logoWhite}
                                    alt="logo"
                                />
                            </Link>
                        </div>

                        <p className="normal-text text-white pb-4 text-center md:text-start">
                            আমার স্পোর্টস ব্লগ সাইট সম্পর্কে 100 লাইনের
                            বিষয়বস্তু: আমি বিশ্বাস করি যে আমার স্পোর্টস ব্লগ
                            সাইটটি আপনাদের সামগ্রিক আশ্রয়
                        </p>

                        <div className="flex items-center pb-4 justify-center md:justify-start">
                            <i className="fa-sharp fa-solid fa-location-dot text-white text-2xl pr-2"></i>

                            <p className="text-white normal-text">
                                50 Rokland Lane Brookyl, NY 1122
                            </p>
                        </div>

                        <div className="text-center md:text-start">
                            <a
                                href="https://www.facebook.com/dribble.digest/"
                                target="_blank"
                            >
                                <i className="fa-brands fa-facebook text-white text-2xl pr-4 hover:text-primary-700"></i>
                            </a>

                            <a
                                href="https://www.instagram.com/dribbledigest/"
                                target="_blank"
                            >
                                <i className="fa-brands fa-instagram text-white text-2xl pr-4 hover:text-primary-700"></i>
                            </a>

                            <a
                                href="https://www.youtube.com/@dribbledigest"
                                target="_blank"
                            >
                                <i className="fa-brands fa-youtube text-white text-2xl pr-4 hover:text-primary-700"></i>
                            </a>
                        </div>
                    </div>

                    <div className="text-center md:text-start">
                        <div className="pb-4">
                            <h3 className="text-white text-2xl font-semibold">
                                Categories
                            </h3>
                        </div>

                        <div>
                            {isLoading && (
                                <div className="mx-auto w-fit">
                                    <Spinner />
                                </div>
                            )}

                            {categories.slice(0, 5).map((category) => {
                                return (
                                    <Link
                                        key={category?.id}
                                        to={`/categories/${category?.title}`}
                                        className="text-white hover:text-primary-700 normal-text block pb-5"
                                    >
                                        <i className="fa-solid fa-caret-right pr-2"></i>
                                        <span>{category?.title}</span>
                                    </Link>
                                );
                            })}
                        </div>
                    </div>

                    <div className="text-center md:text-start">
                        <div className="pb-4">
                            <h3 className="text-white text-2xl font-semibold">
                                Quick Links
                            </h3>
                        </div>

                        <div>
                            <Link
                                to="/blogs"
                                className="text-white hover:text-primary-700 normal-text block pb-5"
                            >
                                <i className="fa-solid fa-caret-right pr-2"></i>
                                <span>Blogs</span>
                            </Link>

                            <Link
                                to="/about"
                                className="text-white hover:text-primary-700 normal-text block pb-5"
                            >
                                <i className="fa-solid fa-caret-right pr-2"></i>
                                <span>About</span>
                            </Link>

                            {categories.slice(5, 8).map((category) => {
                                return (
                                    <Link
                                        key={category?.id}
                                        to={`/categories/${category?.title}`}
                                        className="text-white hover:text-primary-700 normal-text block pb-5"
                                    >
                                        <i className="fa-solid fa-caret-right pr-2"></i>
                                        <span>Category 1</span>
                                    </Link>
                                );
                            })}
                        </div>
                    </div>

                    <div className="text-center md:text-start">
                        <div className="pb-4">
                            <h3 className="text-white text-2xl font-semibold">
                                Newsletter
                            </h3>
                        </div>

                        {/* <p className="text-white normal-text pb-4"></p> */}

                        <div>
                            <input
                                type="email"
                                className="bg-transparent placeholder-white border border-gray-300 text-white text-sm rounded-lg focus:ring-primary-700 focus:border-primary-700 block w-full px-5 py-3.5 mb-2"
                                placeholder="Your Email Address"
                                required
                            />
                            <button
                                type="button"
                                className="text-white w-full bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-3.5 focus:outline-none"
                            >
                                Subscribe
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-black normal-text text-white text-center p-5 md:p-10">
                Developed By AWSDYN. All Rights Reserved
            </div>
        </section>
    );
};

export default Footer;
