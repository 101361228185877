import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Layout from "../../components/Common/Layout";
import Spinner from "../../components/Common/Spinner";
import formatDate from "../../helpers/formatDate";
import useBlogs from "../../hooks/useBlogs";
import Blog from "../../components/Common/Blog";

const BlogDetails = () => {
  // Get Blog And Related Blogs
  const [blog, setBlog] = useState({});
  const [relatedBlogs, setRelatedBlogs] = useState([]);

  let { blogId } = useParams();
  const { getBlog, getBlogsByCategory, isLoading } = useBlogs();

  useEffect(() => {
    getBlog({ id: blogId }).then((result) => {
      setBlog(result);

      getBlogsByCategory({
        category: result?.category?.title,
        page: 1,
        limit: 3,
      }).then((relatedResult) => {
        setRelatedBlogs(relatedResult);
      });
    });
  }, [blogId]);

  // Description Image Responsive
  const descriptionRef = useRef(null);
  const [responsiveDone, setResponsiveDone] = useState(false);

  useEffect(() => {
    if (!responsiveDone) {
      const responsiveImgInterval = setInterval(() => {
        console.log("processing");
        if (!responsiveDone) {
          descriptionImageResponsive();
        }
      }, 1000);

      return () => clearInterval(responsiveImgInterval);
    }
  }, [responsiveDone]);

  const descriptionImageResponsive = () => {
    const descriptionImages = descriptionRef?.current?.querySelectorAll("img");

    if (descriptionImages !== undefined) {
      for (let i = 0; i < descriptionImages?.length; i++) {
        const image = descriptionImages[i];
        image.removeAttribute("width");
        image.removeAttribute("height");
        image.style.width = "100%";
        image.classList.add("rounded-2xl");
      }
      setResponsiveDone(true);
    }
  };

  return (
    <Layout>
      {isLoading ? (
        <div className="mx-auto w-fit pb-8">
          <Spinner />
        </div>
      ) : (
        <section className="bg-white">
          <div className="pb-8 px-4 mx-auto max-w-screen-xl lg:pb-16 lg:px-6">
            {/* Cover Image */}
            <div className="relative mb-8">
              <img
                className="w-full rounded-2xl"
                src={blog?.coverImage}
                alt=""
              />

              <div
                className="absolute top-0 left-0 w-full rounded-2xl"
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.4)",
                }}
              >
                <img
                  className="w-full invisible"
                  src={blog?.coverImage}
                  alt=""
                />
              </div>

              <div className="absolute top-0 bottom-0 right-0 left-0 m-auto h-fit w-fit text-center">
                <header className="mb-4 lg:mb-6 not-format flex flex-col items-center">
                  <span className="bg-primary-100 text-primary-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded w-fit mb-2">
                    <svg
                      className="mr-1 w-3 h-3"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z"></path>
                    </svg>

                    {blog?.category?.title}
                  </span>

                  <h1 className="text-xl md:text-3xl font-extrabold leading-tight text-gray-100 lg:text-4xl mb-1">
                    {blog?.title}
                  </h1>

                  <span className="text-sm text-gray-100 font-bold">
                    {formatDate(blog?.createdAt)}
                  </span>
                </header>
              </div>
            </div>

            {/* Blog Text */}
            <article className="format format-sm sm:format-base lg:format-lg format-blue mb-8">
              <div
                className="text-justify mb-6"
                dangerouslySetInnerHTML={{
                  __html: blog?.description,
                }}
                ref={descriptionRef}
              ></div>

              <address className="flex items-center mb-6 not-italic">
                <div className="inline-flex items-center mr-3 text-sm text-gray-900">
                  <img
                    className="mr-4 w-16 h-16 rounded-full"
                    src={blog?.author?.image}
                    alt="Jese Leos"
                  />
                  <div>
                    <a
                      href="#"
                      rel="author"
                      className="text-xl font-bold text-gray-900"
                    >
                      {`${blog?.author?.firstName} ${blog?.author?.lastName}`}
                    </a>

                    <p className="text-base font-light text-gray-500">
                      {blog?.author?.bio}
                    </p>

                    <p className="text-base font-light text-gray-500">
                      <time>{formatDate(blog?.createdAt)}</time>
                    </p>
                  </div>
                </div>
              </address>
            </article>

            {/* Blog Images */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              {blog?.images?.map((image) => {
                return (
                  <img
                    key={Math.random() * Math.random()}
                    className="rounded-2xl"
                    src={image}
                  />
                );
              })}
            </div>
          </div>
        </section>
      )}

      {/* Related Post */}
      {relatedBlogs.length > 0 && (
        <section className="bg-white">
          <div className="pb-8 px-4 mx-auto max-w-screen-xl lg:pb-16 lg:px-6">
            <div className="mx-auto max-w-screen-sm text-center lg:mb-16 mb-8">
              <h2 className="mb-4 text-3xl lg:text-4xl tracking-tight font-extrabold text-gray-900">
                Related Blogs
              </h2>

              <p className="font-light text-gray-500 sm:text-xl">
                We use an agile approach to test assumptions and connect with
                the needs of your audience early and often.
              </p>
            </div>

            <div className="grid gap-8 lg:grid-cols-3">
              {relatedBlogs.map((blog) => {
                if (blog?.status === "active") {
                  return <Blog key={blog?.id} blog={blog} />;
                }
              })}
            </div>
          </div>
        </section>
      )}
    </Layout>
  );
};

export default BlogDetails;
