function formatDate(inputDate) {
    // Convert input string to a Date object
    const date = new Date(inputDate);

    // Define month names
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    // Get day, month, and year from Date object
    const day = date.getDate().toString().padStart(2, "0");
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    // Return formatted string
    return `${day} ${month}, ${year}`;
}

export default formatDate;
