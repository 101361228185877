import {
    endAt,
    equalTo,
    get,
    limitToLast,
    onValue,
    orderByChild,
    orderByKey,
    query,
    ref,
    remove,
    set,
    update,
} from "firebase/database";
import { useState } from "react";
import { db } from "../firebase/firebase";

const useBlogs = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [hasMore, setHasMore] = useState(false);
    const [hasMoreForCategory, setHasMoreForCategory] = useState(false);
    const [hasMoreForTitle, setHasMoreForTitle] = useState(false);
    const [prevCountForTitle, setPrevCountForTitle] = useState(0);
    const [prevCountForCategory, setPrevCountForCategory] = useState(0);

    // create blog
    const createBlog = (data) => {
        const promise = new Promise(async (resolve, reject) => {
            try {
                setError("");
                setSuccess("");
                setIsLoading(true);

                // Get Id
                const blogRef = ref(db, "blogs/");
                const snapshot = await get(blogRef);
                const blogData = snapshot.val();
                const ids = Object.keys(blogData || {});
                const id =
                    ids.length > 0 ? Math.max(...ids.map(Number)) + 1 : 1;

                const newBlog = {
                    title: data.title,
                    author: data.author,
                    category: data.category,
                    description: data.description,
                    coverImage: data.coverImage,
                    thumbnail: data.thumbnail,
                    images: data.images,
                    status: data.status,
                    createdAt: data.createdAt,
                    id: id,
                };

                await set(ref(db, "blogs/" + id), newBlog);
                setSuccess("Blog created successfully!");
                setIsLoading(false);
                resolve(newBlog);
            } catch (error) {
                const errorMessage = error.message;
                setError(errorMessage);
                setIsLoading(false);
                reject(errorMessage);
            }
        });

        return promise;
    };

    // get blogs by pagination
    const getBlogs = (data) => {
        const promise = new Promise(async (resolve, reject) => {
            try {
                setError("");
                setSuccess("");
                setIsLoading(true);

                const blogsRef = query(
                    ref(db, "blogs/"),
                    orderByKey(),
                    endAt(data.page.toString()),
                    limitToLast(data.limit)
                );

                onValue(blogsRef, (snapshot) => {
                    const blogsData = snapshot.val();

                    if (blogsData) {
                        setHasMore(
                            Object.values(blogsData).length === data.limit
                        );
                        setSuccess("Blogs fetched successfully!");
                        setIsLoading(false);
                        resolve(Object.values(blogsData).reverse());
                    } else {
                        setHasMore(false);
                        setSuccess("Blogs fetched successfully!");
                        setIsLoading(false);
                        resolve([]);
                    }
                });
            } catch (error) {
                const errorMessage = error.message;
                setError(errorMessage);
                setIsLoading(false);
                reject(errorMessage);
            }
        });

        return promise;
    };

    // get all blogs
    const getAllBlogs = () => {
        const promise = new Promise(async (resolve, reject) => {
            try {
                setError("");
                setSuccess("");
                setIsLoading(true);

                const blogsRef = query(ref(db, "blogs/"), orderByKey());

                onValue(blogsRef, (snapshot) => {
                    const blogsData = snapshot.val();

                    if (blogsData) {
                        setSuccess("Blogs fetched successfully!");
                        setIsLoading(false);
                        resolve(Object.values(blogsData).reverse());
                    } else {
                        setSuccess("Blogs fetched successfully!");
                        setIsLoading(false);
                        resolve([]);
                    }
                });
            } catch (error) {
                const errorMessage = error.message;
                setError(errorMessage);
                setIsLoading(false);
                reject(errorMessage);
            }
        });

        return promise;
    };

    // get single blog
    const getBlog = (data) => {
        const promise = new Promise(async (resolve, reject) => {
            try {
                setError("");
                setSuccess("");
                setIsLoading(true);

                const blogRef = ref(db, "blogs/" + data.id);

                onValue(blogRef, (snapshot) => {
                    const blogData = snapshot.val();

                    if (blogData) {
                        setSuccess("Blog fetched successfully!");
                        setIsLoading(false);
                        resolve(blogData);
                    } else {
                        setSuccess("Blog fetched successfully!");
                        setIsLoading(false);
                        resolve({});
                    }
                });
            } catch (error) {
                const errorMessage = error.message;
                setError(errorMessage);
                setIsLoading(false);
                reject(errorMessage);
            }
        });

        return promise;
    };

    // get last blog
    const getLastBlog = () => {
        const promise = new Promise(async (resolve, reject) => {
            try {
                setError("");
                setSuccess("");
                setIsLoading(true);

                const blogsRef = query(
                    ref(db, "blogs/"),
                    orderByKey(),
                    limitToLast(1)
                );

                onValue(blogsRef, (snapshot) => {
                    const blogsData = snapshot.val();

                    if (blogsData) {
                        setSuccess("Blog fetched successfully!");
                        setIsLoading(false);
                        resolve(Object.values(blogsData)[0]);
                    } else {
                        setSuccess("Blog fetched successfully!");
                        setIsLoading(false);
                        resolve({});
                    }
                });
            } catch (error) {
                const errorMessage = error.message;
                setError(errorMessage);
                setIsLoading(false);
                reject(errorMessage);
            }
        });

        return promise;
    };

    // update blog
    const updateBlog = (data) => {
        const promise = new Promise(async (resolve, reject) => {
            try {
                setError("");
                setSuccess("");
                setIsLoading(true);

                await update(ref(db, "blogs/" + data.id), data);
                setSuccess("Blog updated successfully!");
                setIsLoading(false);
                resolve();
            } catch (error) {
                const errorMessage = error.message;
                setError(errorMessage);
                setIsLoading(false);
                reject(errorMessage);
            }
        });

        return promise;
    };

    // delete blog
    const deleteBlog = (data) => {
        const promise = new Promise(async (resolve, reject) => {
            try {
                setError("");
                setSuccess("");
                setIsLoading(true);

                await remove(ref(db, "blogs/" + data.id), data);
                setSuccess("Blog deleted successfully!");
                setIsLoading(false);
                resolve();
            } catch (error) {
                const errorMessage = error.message;
                setError(errorMessage);
                setIsLoading(false);
                reject(errorMessage);
            }
        });

        return promise;
    };

    // get blogs by category
    const getBlogsByCategory = (data) => {
        const promise = new Promise(async (resolve, reject) => {
            try {
                setError("");
                setSuccess("");
                setIsLoading(true);

                const page = data.page || 1;
                const offset = (page - 1) * data.limit;

                const blogsRef = query(
                    ref(db, "blogs/"),
                    orderByChild("category/title"),
                    equalTo(data.category),
                    limitToLast(data.limit + offset)
                );

                onValue(blogsRef, (snapshot) => {
                    const blogsData = snapshot.val();

                    if (blogsData) {
                        const blogs = Object.values(blogsData);

                        if (page === 1) {
                            setHasMoreForCategory(true);
                            setSuccess("Blogs fetched successfully!");
                            setIsLoading(false);
                            setPrevCountForCategory(blogs.length);
                            resolve(blogs.reverse());
                        } else if (blogs.length === prevCountForCategory) {
                            setHasMoreForCategory(false);
                            setIsLoading(false);
                            resolve([]);
                        } else {
                            setHasMoreForCategory(true);
                            setSuccess("Blogs fetched successfully!");
                            setIsLoading(false);
                            setPrevCountForCategory(blogs.length);
                            resolve(blogs.reverse());
                        }
                    } else {
                        setHasMoreForCategory(false);
                        setSuccess("Blogs fetched successfully!");
                        setIsLoading(false);
                        resolve([]);
                    }
                });
            } catch (error) {
                const errorMessage = error.message;
                setError(errorMessage);
                setIsLoading(false);
                reject(errorMessage);
            }
        });

        return promise;
    };

    // get blogs by title
    const getBlogsByTitle = (data) => {
        const promise = new Promise(async (resolve, reject) => {
            try {
                setError("");
                setSuccess("");
                setIsLoading(true);

                const page = data.page || 1;
                const offset = (page - 1) * data.limit;

                const blogsRef = query(
                    ref(db, "blogs/"),
                    orderByChild("title"),
                    equalTo(data.title),
                    limitToLast(data.limit + offset)
                );

                onValue(blogsRef, (snapshot) => {
                    const blogsData = snapshot.val();

                    if (blogsData) {
                        const blogs = Object.values(blogsData);

                        if (page === 1) {
                            setHasMoreForTitle(true);
                            setSuccess("Blogs fetched successfully!");
                            setIsLoading(false);
                            setPrevCountForTitle(blogs.length);
                            resolve(blogs.reverse());
                        } else if (blogs.length === prevCountForTitle) {
                            setHasMoreForTitle(false);
                            setIsLoading(false);
                            resolve([]);
                        } else {
                            setHasMoreForTitle(true);
                            setSuccess("Blogs fetched successfully!");
                            setIsLoading(false);
                            setPrevCountForTitle(blogs.length);
                            resolve(blogs.reverse());
                        }
                    } else {
                        setHasMoreForTitle(false);
                        setSuccess("Blogs fetched successfully!");
                        setIsLoading(false);
                        resolve([]);
                    }
                });
            } catch (error) {
                const errorMessage = error.message;
                setError(errorMessage);
                setIsLoading(false);
                reject(errorMessage);
            }
        });

        return promise;
    };

    return {
        isLoading,
        error,
        setError,
        success,
        setSuccess,
        hasMore,
        hasMoreForCategory,
        hasMoreForTitle,
        createBlog,
        getBlogs,
        getAllBlogs,
        getBlog,
        getLastBlog,
        updateBlog,
        deleteBlog,
        getBlogsByCategory,
        getBlogsByTitle,
    };
};

export default useBlogs;
