import React from "react";
import Layout from "../../components/Common/Layout";
import logo from "../../images/logo.png";

const About = () => {
    return (
        <Layout>
            <section className="bg-white">
                <div className="pb-8 px-4 mx-auto max-w-screen-xl lg:pb-16 lg:px-6">
                    <div className="mx-auto max-w-screen-sm text-center lg:mb-16 mb-8">
                        <h2 className="mb-4 text-3xl lg:text-4xl tracking-tight font-extrabold text-gray-900">
                            About Us
                        </h2>

                        <p className="font-light text-gray-500 sm:text-xl">
                            We use an agile approach to test assumptions and
                            connect with the needs of your audience early and
                            often.
                        </p>
                    </div>

                    <div class="grid lg:gap-8 xl:gap-0 lg:grid-cols-12">
                        <div class="mr-auto flex flex-col justify-center place-self-center lg:col-span-7">
                            <h1 class="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl">
                                Dribble Digest
                            </h1>

                            <p class="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl text-justify">
                                Lorem ipsum, dolor sit amet consectetur
                                adipisicing elit. Voluptas distinctio enim
                                deleniti laudantium in voluptatibus libero ab
                                totam, tempore ipsum ut cumque eligendi
                                explicabo sed voluptates atque fugit excepturi,
                                delectus vitae dolor fuga perferendis deserunt
                                qui voluptate. Vel eum numquam laborum quidem
                                libero aspernatur, mollitia non veniam eligendi
                            </p>
                        </div>

                        <div class="hidden lg:mt-0 lg:col-span-5 lg:flex">
                            <img className="w-full" src={logo} alt="" />
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default About;
