import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import About from "./pages/About/About";
import BlogDetails from "./pages/BlogDetails/BlogDetails";
import Blogs from "./pages/Blogs/Blogs";
import Categories from "./pages/Categories/Categories";
import Home from "./pages/Home/Home";
import NotFound from "./pages/NotFound/NotFound";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route
                    exact
                    path="/blogDetails/:blogId"
                    element={<BlogDetails />}
                />
                <Route
                    exact
                    path="/categories/:category"
                    element={<Categories />}
                />
                <Route exact path="/blogs" element={<Blogs />} />
                <Route exact path="/about" element={<About />} />

                {/* Not Found */}
                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
