import {
    endAt,
    equalTo,
    get,
    limitToLast,
    onValue,
    orderByChild,
    orderByKey,
    query,
    ref,
    remove,
    set,
    update,
} from "firebase/database";
import { useState } from "react";
import { db } from "../firebase/firebase";

const useCategories = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [hasMore, setHasMore] = useState(false);
    const [hasMoreForTitle, setHasMoreForTitle] = useState(false);
    const [prevCountForTitle, setPrevCountForTitle] = useState(0);

    // create category
    const createCategory = (data) => {
        const promise = new Promise(async (resolve, reject) => {
            try {
                setError("");
                setSuccess("");
                setIsLoading(true);

                // Get Id
                const categoryRef = ref(db, "categories/");
                const lastCategoryQuery = query(
                    categoryRef,
                    orderByChild("id"),
                    limitToLast(1)
                );
                const snapshot = await get(lastCategoryQuery);
                const categoryData = snapshot.val();
                const id = categoryData
                    ? parseInt(Object.values(categoryData)[0].id) + 1
                    : 1;

                const newCategory = {
                    title: data.title,
                    image: data.image ? data.image : "",
                    createdAt: data.createdAt,
                    id: id,
                };

                await set(ref(db, "categories/" + id), newCategory);
                setSuccess("Category created successfully!");
                setIsLoading(false);
                resolve(newCategory);
            } catch (error) {
                const errorMessage = error.message;
                setError(errorMessage);
                setIsLoading(false);
                reject(errorMessage);
            }
        });

        return promise;
    };

    // get categories by pagination
    const getCategories = (data) => {
        const promise = new Promise(async (resolve, reject) => {
            try {
                setError("");
                setSuccess("");
                setIsLoading(true);

                const categoriesRef = query(
                    ref(db, "categories/"),
                    orderByKey(),
                    endAt(data.page.toString()),
                    limitToLast(data.limit)
                );

                onValue(categoriesRef, (snapshot) => {
                    const categoriesData = snapshot.val();

                    if (categoriesData) {
                        setHasMore(
                            Object.values(categoriesData).length === data.limit
                        );
                        setSuccess("Categories fetched successfully!");
                        setIsLoading(false);
                        resolve(Object.values(categoriesData).reverse());
                    } else {
                        setHasMore(false);
                        setSuccess("Categories fetched successfully!");
                        setIsLoading(false);
                        resolve([]);
                    }
                });
            } catch (error) {
                const errorMessage = error.message;
                setError(errorMessage);
                setIsLoading(false);
                reject(errorMessage);
            }
        });

        return promise;
    };

    // get all categories
    const getAllCategories = () => {
        const promise = new Promise(async (resolve, reject) => {
            try {
                setError("");
                setSuccess("");
                setIsLoading(true);

                const categoriesRef = query(
                    ref(db, "categories/"),
                    orderByKey()
                );

                onValue(categoriesRef, (snapshot) => {
                    const categoriesData = snapshot.val();

                    if (categoriesData) {
                        setSuccess("Categories fetched successfully!");
                        setIsLoading(false);
                        resolve(Object.values(categoriesData).reverse());
                    } else {
                        setSuccess("Categories fetched successfully!");
                        setIsLoading(false);
                        resolve([]);
                    }
                });
            } catch (error) {
                const errorMessage = error.message;
                setError(errorMessage);
                setIsLoading(false);
                reject(errorMessage);
            }
        });

        return promise;
    };

    // get single category
    const getCategory = (data) => {
        const promise = new Promise(async (resolve, reject) => {
            try {
                setError("");
                setSuccess("");
                setIsLoading(true);

                const categoryRef = ref(db, "categories/" + data.id);

                onValue(categoryRef, (snapshot) => {
                    const categoryData = snapshot.val();

                    if (categoryData) {
                        setSuccess("Category fetched successfully!");
                        setIsLoading(false);
                        resolve(categoryData);
                    } else {
                        setSuccess("Category fetched successfully!");
                        setIsLoading(false);
                        resolve({});
                    }
                });
            } catch (error) {
                const errorMessage = error.message;
                setError(errorMessage);
                setIsLoading(false);
                reject(errorMessage);
            }
        });

        return promise;
    };

    // get last category
    const getLastCategory = () => {
        const promise = new Promise(async (resolve, reject) => {
            try {
                setError("");
                setSuccess("");
                setIsLoading(true);

                const categoriesRef = query(
                    ref(db, "categories/"),
                    orderByKey(),
                    limitToLast(1)
                );

                onValue(categoriesRef, (snapshot) => {
                    const categoriesData = snapshot.val();

                    if (categoriesData) {
                        setSuccess("Category fetched successfully!");
                        setIsLoading(false);
                        resolve(Object.values(categoriesData)[0]);
                    } else {
                        setSuccess("Category fetched successfully!");
                        setIsLoading(false);
                        resolve({});
                    }
                });
            } catch (error) {
                const errorMessage = error.message;
                setError(errorMessage);
                setIsLoading(false);
                reject(errorMessage);
            }
        });

        return promise;
    };

    // update category
    const updateCategory = (data) => {
        const promise = new Promise(async (resolve, reject) => {
            try {
                setError("");
                setSuccess("");
                setIsLoading(true);

                await update(ref(db, "categories/" + data.id), data);
                setSuccess("Category updated successfully!");
                setIsLoading(false);
                resolve();
            } catch (error) {
                const errorMessage = error.message;
                setError(errorMessage);
                setIsLoading(false);
                reject(errorMessage);
            }
        });

        return promise;
    };

    // delete category
    const deleteCategory = (data) => {
        const promise = new Promise(async (resolve, reject) => {
            try {
                setError("");
                setSuccess("");
                setIsLoading(true);

                await remove(ref(db, "categories/" + data.id), data);
                setSuccess("Category deleted successfully!");
                setIsLoading(false);
                resolve();
            } catch (error) {
                const errorMessage = error.message;
                setError(errorMessage);
                setIsLoading(false);
                reject(errorMessage);
            }
        });

        return promise;
    };

    // get categories by title
    const getCategoriesByTitle = (data) => {
        const promise = new Promise(async (resolve, reject) => {
            try {
                setError("");
                setSuccess("");
                setIsLoading(true);

                const page = data.page || 1;
                const offset = (page - 1) * data.limit;

                const categoriesRef = query(
                    ref(db, "categories/"),
                    orderByChild("title"),
                    equalTo(data.title.trim()),
                    limitToLast(data.limit + offset)
                );

                onValue(categoriesRef, (snapshot) => {
                    const categoriesData = snapshot.val();

                    if (categoriesData) {
                        const categories = Object.values(categoriesData);

                        if (page === 1) {
                            setHasMoreForTitle(true);
                            setSuccess("Categories fetched successfully!");
                            setIsLoading(false);
                            setPrevCountForTitle(categories.length);
                            resolve(categories.reverse());
                        } else if (categories.length === prevCountForTitle) {
                            setHasMoreForTitle(false);
                            setIsLoading(false);
                            resolve([]);
                        } else {
                            setHasMoreForTitle(true);
                            setSuccess("Categories fetched successfully!");
                            setIsLoading(false);
                            setPrevCountForTitle(categories.length);
                            resolve(categories.reverse());
                        }
                    } else {
                        setHasMoreForTitle(false);
                        setSuccess("Categories fetched successfully!");
                        setIsLoading(false);
                        resolve([]);
                    }
                });
            } catch (error) {
                const errorMessage = error.message;
                setError(errorMessage);
                setIsLoading(false);
                reject(errorMessage);
            }
        });

        return promise;
    };

    return {
        isLoading,
        error,
        setError,
        success,
        setSuccess,
        hasMore,
        hasMoreForTitle,
        createCategory,
        getCategories,
        getAllCategories,
        getCategory,
        getLastCategory,
        updateCategory,
        deleteCategory,
        getCategoriesByTitle,
    };
};

export default useCategories;
